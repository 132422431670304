import * as React from 'react'
import * as styles from './footer.module.css'
import SNLogo from '../../images/SellciusNetwork_logo.png'
import { Col, Container, Row } from 'react-bootstrap'
import { Icon } from 'semantic-ui-react'
import SocialConnect from '../socialConnect/socialConnect'


function Footer() {
    return (
        <footer className={styles.footer_background}>
            <Container>
                <Row>
                    <Col xs={12} lg={3}>
                        <img src={SNLogo} />
                        <SocialConnect />
                        <br />
                        <div className={styles.footer_contactLine}>(720) 281-9676</div>
                        <div className={styles.footer_contactLine}>web@sellciusnetworks.com</div>
                    </Col>
                    <Col lg={0} xs={12} className="d-none d-xs-block">
                        <hr />
                    </Col>
                    <Col xs={12} lg={3}>
                        <div className={styles.footer_subhead}><h3>Site Navigation</h3></div>
                        <ul className={styles.footer_listItems}>
                            <li>About</li>
                            <li>Managed Properties</li>
                            <li>Request a Quote</li>
                        </ul>
                    </Col>
                    <Col lg={0} xs={12} className="d-none d-xs-block">
                        <hr />
                    </Col>
                    <Col xs={12} lg={3}>
                    <div className={styles.footer_subhead}><h3>Marketing Solutions</h3></div>
                    <ul className={styles.footer_listItems}>
                        <li>A/B Experimentation </li>
                        <li>Calls & SMS</li>
                        <li>Offline Marketing</li>
                        <li>Custom Integrations</li>
                        <li>Advanced Data Analytics</li>
                        <li>Data Aggregation/Scraping</li>
                    </ul>
                    </Col>
                    <Col lg={0} xs={12} className="d-none d-xs-block">
                        <hr />
                    </Col>
                    <Col xs={12} lg={3}>
                    <div className={styles.footer_subhead}><h3>Code Solutions</h3></div>
                    <ul className={styles.footer_listItems}>
                        <li>Web & App Development</li>
                        <li>IoT Engineering</li>
                        <li>Data Storage</li>
                        <li>Application Health Monitoring</li>
                        <li>API Development</li>
                    </ul>
                    </Col>                    
                </Row>  
                <div className={styles.footer_disclaimer}>© {new Date().getFullYear()}, All Rights Reserved</div>
            </Container>
        </footer>
    )
}

export default Footer