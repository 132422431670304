import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import '../../css/global.css'
import * as styles from './header.module.css'
import './header.module.css'
import SNLogo from '../../images/SellciusNetwork_logo.png'
import { Navbar, Nav, Col, Row, Container, NavDropdown } from "react-bootstrap"
import FizzyButton from "../fizzyButton/fizzyButton"
import { useEffect, useRef, useState } from "react"
import { window } from 'browser-monads';


function Header({siteTitle}) {

  const [offset, setOffset] = useState(0)
  const headerRef = useRef();
  const logoRef = useRef();
  const solutionsSubMenuRef = useRef();
  const solutionsSubMenuContainer = useRef();
  

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.pageYOffset);
      }
    }
  }, [])

  useEffect(() => {
    solutionsSubMenuRef.current.onclick = () => {
      let showSubMenu = solutionsSubMenuContainer.current.style.display === "block" ? "none" : "block";
      solutionsSubMenuContainer.current.style.display = showSubMenu;
    }

    solutionsSubMenuRef.current.class += `${styles.header_navItem}`;
  }, [solutionsSubMenuRef])

  useEffect(() => {
    if (offset > 50 && headerRef) {
      console.log(headerRef.current.className);
      headerRef.current.className = `${styles.header_background} ${styles.header_background_solidThin}`;
      // logoRef.current.width = "200"
    } else {
      headerRef.current.className = `${styles.header_background}`;
      // logoRef.current.width = "350"

    }
  }, [offset])
  
  return (
    <header className={`${styles.header_background}`} ref={headerRef}>
      <Navbar expand="lg" variant="dark" className={styles.black_background} fixed="top">
          <Navbar.Brand className={styles.header_navBarBrand}><Link to="/" name="home page"><img src={SNLogo} style={{padding: "0"}} ref={logoRef} alt="Sellcius Networks Logo" id={styles.snLogo} /></Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" id={styles.pushLeft} />
          <Navbar.Collapse id={styles.basic_navbar_nav}>
            <Nav id={styles.navbar_nav} className="navbar_nav">
              <Link className={`${styles.header_navItem} navLink`} to="/about">About</Link>
              <span className={styles.header_navItem} ref={solutionsSubMenuRef}>Soultions</span>
              <Link className={styles.header_navItem} to="/properties">Properties</Link>
              <FizzyButton target="/getQuote">Request a Quote</FizzyButton>
              <Col style={{width: "20px"}}></Col>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
      <div id={styles.header_solutions_submenu} ref={solutionsSubMenuContainer}>
        <Container>
          <Row>
            <Col xs={12} lg={3}>
              <h4><Link to="/callSMSSolutions">Call & SMS Solutions</Link></h4>
              <ul>
                <li><Link to="/callSMSSolutions">Custom Call & SMS Integrations</Link></li>
                <li><Link to="/realTimeWebhooks">Real-time Webhooks</Link></li>
                <li><Link to="/callEnrichment">Call Analytics Enrichment</Link></li>
                <li><Link to="/callCenter">Call Center Integrations</Link></li>
              </ul>
            </Col>
            <Col xs={12} lg={3}>
              <h4><Link to="/integrationSolutions">Integration Solutions</Link></h4>
              <ul>
                <li><Link to="/cloudSolutions">AWS, Azure, Google</Link></li>
                <li><Link to="/serverToServer">Server-to-Server Solutions</Link></li>
                <li><Link to="/customAPI">Custom API Development</Link></li>
                <li><Link to="/autoOptimizations">Marketing Auto Optimizations</Link></li>
              </ul>
            </Col>
            <Col xs={12} lg={3}>
              <h4><Link to="/managedSolutions">Managed Solutions</Link></h4>
              <ul>
                <li><Link to="/customWebsites">Custom Websites</Link></li>
                <li><Link to="/mobileApps">Custom Mobile Apps</Link></li>
                <li><Link to="/managedServers">Managed DB's & SFTPs</Link></li>
                <li><Link to="/appHealth">Website & App Health Monitoring</Link></li>
              </ul>
            </Col>
            <Col xs={12} lg={3}>
              <h4><Link to="/dataScience">Data Science Solutions</Link></h4>
              <ul>
                <li><Link to="/abExp">A/B Experimentation</Link></li>
                <li><Link to="/stats">Statistical Analysis & Reporting</Link></li>
                <li><Link to="/dataEnrichment">Data Enrichment</Link></li>
                <li><Link to="/scrapping">Web/Data Scrapping</Link></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
