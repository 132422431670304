import * as React from 'react'
import { Icon } from 'semantic-ui-react'

function SocialConnect() {
    return (
        <div style={{fontSize: "2em"}}>
           <Icon name="facebook square" />
           <Icon name="youtube square" />
           <Icon name="twitter square" />
           <Icon name="instagram" />
           <Icon name="linkedin square" />
        </div>
    )
}

export default SocialConnect