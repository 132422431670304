import { Link } from 'gatsby'
import * as React from 'react'
import './fizzyButton.css'

function AnimatedButton( { children, filled, secondary, target, ...rest } ) {
    return (
        <Link to={target}>
            <div id="animatedButtonContainer">
                <div className={`dir-control ${secondary ? 'dir-control--secondary' : ''} ${filled ? 'dir-control--filled' : ''}`} {...rest}>
                    {children}
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <b aria-hidden="true">{children}</b>
                    <b aria-hidden="true">{children}</b>
                    <b aria-hidden="true">{children}</b>
                    <b aria-hidden="true">{children}</b>    
                </div> 
            </div>
        </Link>
    )
}

export default AnimatedButton

